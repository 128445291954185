<template>
  <add-edit-page>
    <template #header="{ validation }">
      <sticky-header title="Zone Edit Setup">
        <h4>{{ basicInfo.name }}</h4>
        <save-cancel :save-enabled="!validation.invalid" on-can @onSave="handleSubmit" />
      </sticky-header>
    </template>
    <div class="spread">
      <basic-info v-model="basicInfo" name-label="Location Name" />
      <main-address v-model="address" />
      <settings :value="settings" :package-list="packageList" :selected-mvr="selectedMvrPackage" :selected-screening="selectedScreeningPackage" @input="update" />
      <div class="module-form">
        <h3>Funnel Settings</h3>
        <div class="logo-preview">
          <file-viewer :image-id="valuePopulate" />
        </div>
        <file-upload-logo label="Change Delivery Area Map" :location-id="$route.params.locationID" filename="deliveryAreaMap" :public="true" @change="onMapChange" />
      </div>
      <team-setup
        :accountManagers="accountManagers"
        :specialists="specialists"
        :selected-a-m="selectedAMs"
        :selected-specialist="selectedSpecialists"
        @onAMChanged="updateAM"
        @onSpecialistChanged="updateSpecialist"
      />
    </div>
    <template #bottomContent="{ validation }">
      <save-cancel :save-enabled="!validation.invalid" on-can @onSave="handleSubmit" />
    </template>
  </add-edit-page>
</template>

<script>
import BasicInfo from "@/components/setup/BasicInfo";
import MainAddress from "@/components/setup/MainAddress";
import Settings from "@/components/setup/Settings";
import FileUploadLogo from "@/components/files/FileUploadLogo";
import { AddEditPage, SaveCancel, StickyHeader, TeamSetup } from "@/components";
import FileViewer from "@/components/files/FileViewerLocation";
import { restApi } from "@/http/http.config";
import {encodeWithParam} from "@/util/Base64Encoding";
import { convertBufferToContent } from "@/util/BufferContentConverter";

export default {
  name: "LocationEditSetup",
  components: { FileViewer, TeamSetup, Settings, MainAddress, BasicInfo, StickyHeader, AddEditPage, SaveCancel, FileUploadLogo }, // FileUpload
  data() {
    return {
      packageList: [],
      flyout: false,
      errors: "",
      test: "Test",
      previousLocationConfiguration: {},
      basicInfo: {
        name: "",
        timeZone: "",
        startDate: "",
        phone: "",
      },
      address: {
        address1: "",
        address2: "",
        city: "",
        state: "",
        postalCode: "",
        country: "US",
      },
      settings: {
        federalTaxID: "",
        taxProgram: "",
        isRecruiting: "",
        ageRequirement: "",
        businessType: "",
        insuranceSetting: "",
        mvrPackageName: "",
        screeningPackageName: "",
      },
      teams: [],
      specialists: [],
      accountManagers: [],
      selectedTeam: null,
      selectedTeams: null,
      selectedSpecialist: null,
      selectedSpecialists: null,
      selectedAM: null,
      selectedAMs: null,
      map: "",
      specalistRemoved: false,
      amRemoved: false,
      param : {
        roles: [ "ONBOARDING_ANALYST", "ONBOARDING_ACCOUNT_MANAGER" ],
        statuses: [ "ACTIVE" ],
        limit: 90000,
        offset: 0,
        searchTerm: "%"
      },
      existsAM: null,
      existsSpecialist: null
    };
  },
  computed: {
    valuePopulate(){
      return this.map;
    },
    selectedMvrPackage() {
      return this.settings.mvrPackageName ? [{ id: this.settings.mvrPackageName, name: this.settings.mvrPackageName }] : [];
    },
    selectedScreeningPackage() {
      return this.settings.screeningPackageName ? [{ id: this.settings.screeningPackageName, name: this.settings.screeningPackageName }] : [];
    },
  },
  mounted() {
    restApi.post(`/location/getLocationSetup`, encodeWithParam({id: this.$route.params.locationID})).then((responseData)=>{
      let data = responseData.data.data;
      if(data?.clientLocation?.deliveryAreaMap) {
         restApi.post('/employee/getUploadedFile',encodeWithParam({fileID: data?.clientLocation?.deliveryAreaMap})).then ((data)=>{
               this.map=convertBufferToContent(data.data.bufferInHex,data.data.contentType)
         })
      }
      this.basicInfo.name = data.clientLocation.name;
      this.basicInfo.timeZone = data?.clientLocation?.timeZone ? data.clientLocation.timeZone : "-08:00";
      this.basicInfo.startDate = data?.clientLocation?.startDate ? data.clientLocation.startDate : "";
      this.basicInfo.phone = data?.clientLocation?.phone ? data.clientLocation.phone : "";
      this.address.address1 = data?.clientLocation?.address?.address1 ? data?.clientLocation?.address?.address1 : "";
      this.address.address2 = data?.clientLocation?.address?.address2 ? data?.clientLocation?.address?.address2 : "";
      this.address.city = data.clientLocation.address ? data.clientLocation.address.city : "";
      this.address.state = data.clientLocation.address ? data.clientLocation.address.state : "";
      this.address.postalCode = data.clientLocation.address ? data.clientLocation.address.postalCode : "";
      this.address.country = data.clientLocation.address ? data.clientLocation.address.country : "";
      this.settings.federalTaxID = data?.clientLocation?.federalTaxID ? data.clientLocation.federalTaxID : "";
      this.settings.taxProgram = data?.clientLocation?.taxProgram ? data?.clientLocation?.taxProgram : "";
      this.settings.isRecruiting = data?.clientLocation?.isRecruiting ? data?.clientLocation?.isRecruiting : false;
      this.settings.ageRequirement = data?.clientLocation?.ageRequirement ? data?.clientLocation?.ageRequirement : "";
      this.settings.businessType = data.clientLocation.businessType ? data.clientLocation.businessType : "";
      this.settings.insuranceSetting = data.clientLocation.insuranceSetting ? data.clientLocation.insuranceSetting : "";
      this.settings.screeningPackageName = data?.clientLocation?.locationConfiguration?.screeningPackageName ? data.clientLocation.locationConfiguration.screeningPackageName : "";
      this.settings.mvrPackageName = data?.clientLocation?.locationConfiguration?.mvrPackageName ? data.clientLocation.locationConfiguration.mvrPackageName : "";
      this.clientName = data.clientLocation.name;
      this.client = data.clientLocation;
      if (data.clientLocation.accountManager) {
        const accountManager = data.clientLocation.accountManager;
        this.existsAM = accountManager.id;
        const name = [accountManager.firstName, accountManager.lastName].join(" ");
        this.selectedAMs = [{ id: accountManager.id, name, specialistID: accountManager.id }];
      }
      if (data.clientLocation.teamSpecialist) {
        const teamSpecialist = data.clientLocation.teamSpecialist;
        this.existsSpecialist = teamSpecialist.id;
        const name = [teamSpecialist.firstName, teamSpecialist.lastName].join(" ");
        this.selectedSpecialists = [{ id: teamSpecialist.id, name, specialistID: teamSpecialist.id }];
      }
  });
  restApi.post('employee/getEmployees', encodeWithParam(this.param))
        .then(data => {
          let employees = data.data.result;
          if(employees && employees.length > 0) { 
            for(let i = 0; i < employees.length; i++){
              if(employees[i].role_name == "ONBOARDING_ACCOUNT_MANAGER") {
                this.accountManagers.push({ id: employees[i].id, name: `${employees[i].firstName} ${employees[i].lastName}`.trim(), specialistID: employees[i].id });
              } else if(employees[i].role_name == "ONBOARDING_ANALYST") {
                this.specialists.push({ id: employees[i].id, name: `${employees[i].firstName} ${employees[i].lastName}`.trim(), specialistID: employees[i].id });
              }
            }
          }
        });
  },
  methods: {
    update(value) {
      this.settings = { ...this.settings, ...value };
    },
    handleSubmit() {
      this.updateClientSetup();
    },
    async onMapChange(data) {
      let data1 = await restApi.post('/employee/getUploadedFile',encodeWithParam({fileID: data.fileID}))
      restApi.post('/employee/updateDeliveryAreaMap',encodeWithParam({fileID: data.fileID, id: this.$route.params.locationID}))
      this.map=convertBufferToContent(data1.data.bufferInHex,data1.data.contentType)
      this.$emit("input", { ...this.$props.value, deliveryAreaMap: data?.fileID });
    },
    async updateClientSetup() {
      let location = {
        id: this.$route.params.locationID,
        name: this.basicInfo.name,
        startDate: this.basicInfo.startDate,
        timeZone: this.basicInfo.timeZone,
        phone: this.basicInfo.phone,
        ageRequirement: this.settings.ageRequirement,
        federalTaxID: this.settings.federalTaxID,
        isRecruiting: this.settings.isRecruiting,
        taxProgram: this.settings.taxProgram,
        locationConfiguration: {},
        selectedSpecialist: this.selectedSpecialist?.id ? this.selectedSpecialist.id: this.selectedSpecialists?.id ? this.existsSpecialist : null,
        selectedAM: this.selectedAM?.id ? this.selectedAM.id : this.selectedAMs?.id ? this.existsAM : null
      }
      if (this.settings.mvrPackageName && this.settings.mvrPackageName.length > 0) {
        location.locationConfiguration.mvrPackageName = this.settings.mvrPackageName;
      }
      if (this.settings.screeningPackageName && this.settings.screeningPackageName.length > 0) {
        location.locationConfiguration.screeningPackageName = this.settings.screeningPackageName;
      }
      await restApi.post('location/UpdateLocation', encodeWithParam(location))
      .then(() => {
        this.$router.back();
      });
    },
    updateTeam(teamChangedEvent) {
      this.selectedTeam = teamChangedEvent.itemAdded;
      if (teamChangedEvent.itemRemoved) {
        this.selectedSpecialists = {};
      }
    },
    updateSpecialist(specialistChangedEvent) {
      this.specalistRemoved = specialistChangedEvent?.items.length > 0 ? true : false;
      this.selectedSpecialist = specialistChangedEvent.itemAdded;
    },
    updateAM(amChangedEvent) {
      this.amRemoved = amChangedEvent?.items.length > 0 ? true : false;
      this.selectedAM = amChangedEvent.itemAdded;
    },
   
  },
};
</script>
